<template>
    <v-select :options="data_arr"></v-select>
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';


Vue.component('v-select', vSelect)

export default {
    props : [
        'data_arr'
    ],
}
</script>