<template>
    <div>
        <div class="modal fade" id="modalPreloader" tabindex="-1" role="dialog" aria-labelledby="modalPreloaderTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content bg-transparent border-0 shadow-none">
                    <img :src="image_preloader" alt="preloaderImg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : [
        'image_preloader'
    ]
}
</script>