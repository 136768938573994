<template>
	<div class="alert fade show w-100" :class="{'alert-danger': type=='error', 'alert-success' : type == 'success'}" role="alert" v-show="is_show">
		<p class="m-0" v-html="message"></p>
	</div>
</template>

<script>
	export default {
		props:[
		'is_show', 'message', 'type'
		],
		mounted() {
		}
	}
</script>
