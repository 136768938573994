var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.is_show,
          expression: "is_show"
        }
      ],
      staticClass: "alert fade show w-100",
      class: {
        "alert-danger": _vm.type == "error",
        "alert-success": _vm.type == "success"
      },
      attrs: { role: "alert" }
    },
    [
      _c("p", {
        staticClass: "m-0",
        domProps: { innerHTML: _vm._s(_vm.message) }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }